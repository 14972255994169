import React from "react"
import {graphql} from "gatsby";

import Layout from '../layouts/default'

import ContentContainer from '../components/content-container'
import Header from '../components/header'
import HeaderContent from '../components/header-content'
import AdvisorItem from '../components/advisor-item'

import styles from './list-advisor/component.module.less'

export default ({data, pageContext}) => (
    <Layout
        locale={pageContext.locale}
        localeLinks={pageContext.localeLinks}
        seo={pageContext.seoMetaTags}
        robots={pageContext.seoMetaRobots}
        canonical={pageContext.seoMetaCanonical}
    >
        {{
            header: (
                <Header
                    locale={pageContext.locale}
                    localeLinks={pageContext.localeLinks}
                    transparent={data.datoCmsSeiten.header !== undefined && data.datoCmsSeiten.header.length > 0}
                >
                    <HeaderContent locale={pageContext.locale} data={data.datoCmsSeiten.header} />
                </Header>
            ),
            content: (
                <ContentContainer>
                    <ol className={styles.list}>
                        {data.allDatoCmsRatgeber.nodes.filter(n => n.slug !== null).map(article => (
                            <li key={article.id} className={styles.item}>
                                <AdvisorItem
                                    locale={pageContext.locale}
                                    data={article}
                                    urlPrefix={pageContext.urlPath || ''}
                                />
                            </li>
                        ))}
                    </ol>
                </ContentContainer>
            )
        }}
    </Layout>
)

export const query = graphql`
    query($locale: String!, $id: String!) {
        datoCmsSeiten(id: {eq: $id }) {
            ...cms_header
            name
        }
    
        allDatoCmsRatgeber(filter: {locale: {eq: $locale}}, sort: {fields: publicationDate, order: DESC}) {
            nodes {
                ...advisor_item
            }
        }
    }
`
